<template>
  <div class="calendar__container py-4">
    <div class="btn-action d-flex justify-content-center align-items-center">
      <v-btn :disabled="!showPrevBtn" fab text small color="grey darken-2">
        <v-icon @click="prev"> mdi-chevron-left </v-icon>
      </v-btn>
    </div>
    <div class="wrapper__date__list">
      <div class="date__list">
        <div
          v-for="(day, idx) in days"
          :key="idx"
          class="date__item"
          :class="{ date__active: focus === day.day }"
          @click="changeDate(day)"
        >
          <div class="date__title">{{ getDate(day.day) }}</div>
          <div class="date">{{ converDate(day.day) }}</div>
        </div>
      </div>
    </div>
    <div class="btn-action d-flex justify-content-center align-items-center">
      <v-btn :disabled="!showNextBtn" fab small text color="grey darken-2">
        <v-icon @click="next"> mdi-chevron-right </v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script>
export default {
  name: 'HorizontalCalendar',
  props: {
    date: {
      type: String,
      default: null,
    },
    days: {
      type: Array,
      default: () => [],
    },
    showNextBtn: {
      type: Boolean,
      default: true,
    },
    showPrevBtn: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      focus: null,
    };
  },
  watch: {
    date(val) {
      this.focus = this.convertDateToTimestamp(val);
    },
  },
  mounted() {
    this.focus = this.convertDateToTimestamp(this.date);
  },
  methods: {
    prev() {
      this.$emit('change', { type: 'prev' });
    },
    next() {
      this.$emit('change', { type: 'next' });
    },
    async viewDay({ date }) {
      this.focus = date;
      this.now = date;
    },
    converDate(date) {
      const dateMoment = this.$moment(date);
      if (dateMoment.isValid()) return this.$moment.unix(date).format('DD/MM');

      return null;
    },
    setDateWidth() {
      if (!this.days.length) return;

      const dateList = document.querySelector('.date__list');
      return {
        width: `${dateList.offsetWidth}/${this.days.length}px`,
      };
    },
    changeDate(item) {
      this.focus = this.convertTimestampToDate(item.day);
      this.$emit('date-change', item);
    },
    getDate(date) {
      const dateMoment = this.$moment(date);
      if (dateMoment.isValid())
        return this.getDayText(this.$moment.unix(date).format('e'));

      return null;
    },
    getDayText(day) {
      switch (Number(day)) {
        case 1:
          return 'T2';
        case 2:
          return 'T3';
        case 3:
          return 'T4';
        case 4:
          return 'T5';
        case 5:
          return 'T6';
        case 6:
          return 'T7';

        default:
          return 'CN';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.calendar__container {
  display: grid;
  grid-template-columns: max-content 1fr max-content;
}

.date__container {
  width: calc(100% - 80px);
}

.wrapper__date__list {
  overflow: auto;
  white-space: nowrap;
}

.date__list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  column-gap: 20px;
  border-bottom: 1px solid #e0e0e0;

  @media only screen and (max-width: 1366px) {
    column-gap: 12px;
  }
}

.date__item {
  padding: 12px;
  text-align: center;
  cursor: pointer;

  &.date__active {
    border-bottom: 2px solid #01645a;
    div {
      color: #01645a !important;
      font-weight: 700 !important;
    }
  }
}

.date__title {
  font-size: 13px;
  line-height: 20px;
  color: #888c9f;
  margin-bottom: 4px;
}

.date {
  font-size: 22px;
  line-height: 24px;
  color: #888c9f;
}
</style>
